import type { Nullable } from '../models/CustomUtilityTypes';
import { WhlModuleType } from '../models/WhlModuleType';

export default (
  permalink: Nullable<string>,
  to?: Nullable<WhlModuleType>
): ComputedRef<string | null> => {
  return computed(() => {
    if (isEmpty(permalink)) return '#';

    const localePath = useLocalePath();
    const whlModuleType = useWhlModuleType();
    const moduleType = to ?? toValue(whlModuleType)!;

    switch (moduleType) {
      case WhlModuleType.Event:
        return localePath('/events/detail/' + permalink);
      case WhlModuleType.Tour:
        return localePath('/tours/detail/' + permalink);
      case WhlModuleType.Poi:
        return localePath('/pois/detail/' + permalink);
      default:
        return null;
    }
  });
};
